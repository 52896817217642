import { Vec2 } from '~~/glxp/ogl/math/Vec2'

let isRunning = false
let needsUpdate = true
let mousePosition = new Vec2(0, 0)

function updateMouse(event) {
  const { clientX, clientY } = event
  mousePosition.set(clientX, clientY)
  needsUpdate = true
}

function onMousemove(event) {
  // only call once per frame
  if (needsUpdate) {
    requestAnimationFrame(() => {
      updateMouse(event)
    })
    needsUpdate = false
  }
}

export function useMouse() {
  if (!isRunning) {
    window.addEventListener('mousemove', onMousemove)
  }
  return mousePosition
}
